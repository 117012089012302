import { Card, Heading, Divider, Grid } from '@enterprise-ui/canvas-ui-react';
import './styles/cardStyles.scss';

export const VestibuleDetailsCard = ({ data }) => {
  return (
    <Card elevation={0} className="hc-mv-xl hc-pa-normal">
      <Heading size={2} className="customHeader">
        Survey Details{' '}
      </Heading>{' '}
      <Grid.Container>
        <Grid.Item md={6} xs={12}>
          <Heading size={5} className="customTextPosition hc-pt-expanded">
            Installation Details
          </Heading>
          {/* <Divider></Divider> */}
          <p className="hc-mv-normal hc-ph-dense">
            <strong>Submitted at: </strong> {data?.submitted_at}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Address: </strong> {data?.address}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Location ID: </strong> {data?.location_id}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Floor: </strong> {data?.floor_id}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Technician Name: </strong> {data?.technician_name}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Vendor Name: </strong> {data?.vendor_name}
          </p>
          {!isNaN(data?.vestibule_details?.attachment_links?.length) && (
            <p className="hc-mv-normal hc-ph-dense">
              <strong>Number of Images uploaded: </strong>{' '}
              {data?.vestibule_details?.attachment_links?.length}
            </p>
          )}
          {data?.vestibule_details?.neptune_x && (
            <>
              <p className="hc-mv-normal hc-ph-dense hc-ph-dense">
                <strong>Neptune-X-Coordinates: </strong>{' '}
                {data?.vestibule_details.neptune_x}
              </p>
              <p className="hc-mb-normal hc-ph-dense hc-ph-dense">
                <strong>Neptune-Y-Coordinates: </strong>{' '}
                {data?.vestibule_details.neptune_y}
              </p>
            </>
          )}
        </Grid.Item>
        <Grid.Item md={6} xs={12}>
          <Divider className="hc-ph-expanded " />
          <Heading size={5} className="customTextPosition hc-pt-expanded ">
            Vestibule Details
          </Heading>

          <p className="hc-mv-normal hc-ph-dense">
            <strong>Vestibule Name: </strong> {data?.vestibule_name}
          </p>
          <p className="hc-mb-normal hc-ph-dense">
            <strong>Vestibule Type: </strong>{' '}
            {data?.vestibule_details?.vestibule_type}
          </p>

          <p className="hc-mb-normal hc-ph-dense">
            <strong>Ceiling Height: </strong>{' '}
            {data?.vestibule_details?.ceiling_height}"
          </p>
          {data?.vestibule_details?.entrance_door_count > 0 && (
            <>
              <p className="hc-mb-normal hc-ph-dense">
                <strong>Entrance Door Count: </strong>{' '}
                {data?.vestibule_details?.entrance_door_count}
              </p>
              <p className="hc-mb-normal hc-ph-dense">
                <strong>Entrance Door Width: </strong>{' '}
                {data?.vestibule_details?.entrance_door_width}"
              </p>
              <p className="hc-mb-normal hc-ph-dense">
                <strong>Exit Door Count: </strong>{' '}
                {data?.vestibule_details?.exit_door_count}
              </p>
              <p className="hc-mb-normal hc-ph-dense">
                <strong>Exit Door Width: </strong>{' '}
                {data?.vestibule_details?.exit_door_width}"
              </p>
            </>
          )}
          {data?.vestibule_details?.door_position && (
            <>
              <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
                <strong>Interior or Exterior Door? </strong>{' '}
                {data?.vestibule_details?.door_position}
              </p>
              <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
                <strong>Single or Double Door? </strong>{' '}
                {data?.vestibule_details?.door_type}
              </p>
              {data?.vestibule_details?.door_type === 'Double' && (
                <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
                  <strong>Do TMs enter through both doors? </strong>{' '}
                  {data?.vestibule_details?.is_each_door_used ? 'Yes' : 'No'}
                </p>
              )}
              {data?.vestibule_details?.door_type === 'Double' &&
                !data?.vestibule_details?.is_each_door_used && (
                  <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
                    <strong>TM enter through Left or Right? </strong>{' '}
                    {data?.vestibule_details?.door_placement}
                  </p>
                )}
            </>
          )}

          <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
            <strong>Are there any obstructions? </strong>{' '}
            {data?.vestibule_details?.is_obstructed ? 'Yes' : 'No'}
          </p>
          {data?.vestibule_details?.obstruction_reason && (
            <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
              <strong>Reasoning: </strong>{' '}
              {data?.vestibule_details?.obstruction_reason}
            </p>
          )}
          {data?.vestibule_details?.obstruction_reason === 'Other' && (
            <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
              <strong>Additional Reasoning: </strong>{' '}
              {data?.vestibule_details?.obstruction_other_reason}
            </p>
          )}
          {data?.vestibule_details?.additional_notes && (
            <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
              <strong>Additional Notes: </strong>{' '}
              {data?.vestibule_details?.additional_notes}
            </p>
          )}
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};
