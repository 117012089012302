import { useState } from 'react';
import { Layout } from '@enterprise-ui/canvas-ui-react';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../hooks/useInstallerAuthContext';

import { LogIn } from './Login/LogIn';
import { MainRouter } from '../components/MainRouter';
import TimeOutHandler from '../components/TimeOutHandler';
import { Header } from '../components/Header';
import { SideNavigation } from '../components/SideNavigation';
import { Footer } from '../components/Footer';

export const Main = () => {
  const auth = useAuth();
  const { isAuthenticated } = auth;
  const { isUserAuthenticated } = useInstallerAuthContext();
  // eslint-disable-next-line no-unused-vars
  const [isActive, setIsActive] = useState(true);
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  if (isAuthenticated() || isUserAuthenticated) {
    return (
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop theme={ "default" | "target" | "blue" | "grey" | "green" | "roundel" }
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop darkMode={ true | false } - Specify darkmode to override the client browser's preferences. Applications previously using the "First Avenue" theme should set darkMode={true}.
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop theme={ "default" | "target" | "blue" | "grey" | "green" | "roundel" }
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop darkMode={ true | false } - Specify darkmode to override the client browser's preferences. Applications previously using the "First Avenue" theme should set darkMode={true}.
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop theme={ "default" | "target" | "blue" | "grey" | "green" | "roundel" }
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop darkMode={ true | false } - Specify darkmode to override the client browser's preferences. Applications previously using the "First Avenue" theme should set darkMode={true}.
      <Layout
        // configure theme here
        theme="default"
        // configure dark mode here
        darkMode="system"
        data-testid="mainAuthenticatedLayout"
      >
        {isUserAuthenticated && (
          <TimeOutHandler
            onActive={() => {
              setIsActive(true);
            }}
            onIdle={() => {
              setIsActive(false);
            }}
          />
        )}
        <Header onSideNavToggle={handleSideNavToggle} />
        <SideNavigation
          isOpen={isSideNavOpen}
          onRequestClose={handleSideNavToggle}
        />
        <MainRouter />
        <Footer />
      </Layout>
    );
  } else {
    return <LogIn />;
  }
};
