import { NavLink, useLocation } from 'react-router-dom';
import { Heading, SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react';
import { useAuth } from '@praxis/component-auth';

export const SideNavigation = ({ isOpen, onRequestClose }) => {
  const location = useLocation();
  const auth = useAuth();
  const { session } = auth;

  return (
    <SideNav
      data-testid="sideNav"
      isVisible={isOpen}
      onRequestClose={onRequestClose}
    >
      <SideNav.SkipLink data-testid="sideNavSkipLink" href="#mainContent">
        Skip to Main Content
      </SideNav.SkipLink>
      <SideNav.Header data-testid="sideNavLogoHeader" as={NavLink} to={'/'}>
        <TargetLogo size="expanded" className="hc-mr-dense" />
        <Heading size={1} className="hc-fs-md">
          IoT Platform | RFID
        </Heading>
      </SideNav.Header>
      <SideNav.Navigation data-testid="sideNavMainNavArea">
        <SideNav.NavigationItem
          as={NavLink}
          to={'/'}
          className={location.pathname === '/' ? 'isSelected' : ''}
        >
          Home
        </SideNav.NavigationItem>
        {/* <SideNav.NavigationItem
          as={NavLink}
          to={'/layoutWithRightRail'}
          className={
            location.pathname === '/layoutWithRightRail' ? 'isSelected' : ''
          }
        >
          Layout With Right Rail
        </SideNav.NavigationItem> */}
        <SideNav.NavigationItem
          as={NavLink}
          to={'/install'}
          className={location.pathname === '/install' ? 'isSelected' : ''}
        >
          Installation
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          as={NavLink}
          to={'/lookup'}
          className={location.pathname === '/lookup' ? 'isSelected' : ''}
        >
          Lookup
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          as={NavLink}
          to={'/calibrationSetupLocation'}
          className={
            location.pathname === '/calibrationSetupLocation'
              ? 'isSelected'
              : ''
          }
        >
          RFID Calibration
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          as={NavLink}
          to={'/survey'}
          className={location.pathname === '/survey' ? 'isSelected' : ''}
        >
          Pre-Install Survey
        </SideNav.NavigationItem>
        {session && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/surveySearch'}
            className={
              location.pathname === '/surveySearch' ? 'isSelected' : ''
            }
          >
            Survey Search
          </SideNav.NavigationItem>
        )}
      </SideNav.Navigation>
    </SideNav>
  );
};
