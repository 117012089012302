import {
  Card,
  Grid,
  Layout,
  Breadcrumbs,
  Heading,
  Form,
} from '@enterprise-ui/canvas-ui-react';
import { Link as ReactLink } from 'react-router-dom';
import { CreatableSelect } from '../../components/CreatableSelect';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import EnterpriseIcon, { ErrorFilledIcon } from '@enterprise-ui/icons';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import {
  vestibulesByStore,
  vestibulesByStoreExt,
  getAddressDetails,
} from '../../app/presurvey/surveySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Button } from 'ui-library';
import { useNavigate } from 'react-router-dom';
import { Message } from '../../components/Message';

export const Survey = () => {
  /* Auth related constants */
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  /*Navigation related constants */
  const navigate = useNavigate();
  let [store, setStore] = useState(user?.installLocation || 'T');
  const storeAddress = useSelector(getAddressDetails);
  const [formData, setFormData] = useState({
    location_id: user?.installLocation,
  });
  const [vendorSubmitted, setVendorSubmitted] = useState(true);
  const { loading, error } = useSelector((state) => state.vestibuleLocation);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { location_id: user?.installLocation || 'T', tech_name: '' },
    mode: 'onBlur',
  });

  const onSubmit = (data, e) => {
    if (!error && !loading && formData?.vendor_name) {
      //Prevents clicking if store doesn't exist
      e.preventDefault();
      navigate(`/survey-reader-list/?q=${data?.location_id}`, {
        state: { formData },
      });
    } else if (!formData?.vendor_name) {
      setVendorSubmitted(false);
    }
  };

  useEffect(() => {
    setToken(getExternalToken(user));
    sessionStorage.clear();
  }, [user]);

  useEffect(() => {
    if (store?.length > 4) {
      if (session) {
        dispatch(vestibulesByStore(store));
      } else if (user) {
        let { token } = user;
        dispatch(vestibulesByStoreExt({ store, token }));
      }
    }
  }, [dispatch, store, session, token, user]);

  const options = [
    { value: 'Telaid', label: 'Telaid' },
    { value: 'ComputaCenter', label: 'ComputaCenter' },
  ];

  return (
    <>
      <Layout.Body data-testid="surveyPage" includeRail>
        <Card className="hc-pa-normal">
          <Breadcrumbs className="breadcrumbSpacing">
            <Breadcrumbs.Item as={ReactLink} to="/">
              Home
            </Breadcrumbs.Item>
            <Breadcrumbs.Item as={ReactLink} to="/survey">
              Pre-Install Survey
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <Grid.Item xs={12}>
            <Heading size={3}> Pre-Install Survey Form</Heading>
          </Grid.Item>
          <Form
            role="form"
            id="registration_form"
            className="hc-mt-md"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid.Container className="hc-pa-normal">
              <Grid.Item xs={12}>
                <Form.Field
                  type="text"
                  label="Location ID (i.e T9999)"
                  data-testid="location_id"
                  id="location_id"
                  required="Please enter a Location ID (i.e T9999)"
                  error={errors.location_id}
                  errorText={errors.location_id && errors.location_id.message}
                  {...register('location_id', {
                    required: 'Please enter a location!',
                    pattern: {
                      value: /T[0-9]{4}/,
                      message:
                        'Invalid location ID! Please follow this format: T1234',
                    },
                    onChange: (e) => {
                      setStore(e.target.value);
                      setFormData({
                        ...formData,
                        location_id: e?.target?.value,
                      });
                    },
                  })}
                />
                {storeAddress && !error && !loading && store.length > 4 && (
                  <p className="hc-fs-xs hc-pl-xs">
                    Address: <em>{storeAddress}</em>
                  </p>
                )}
                {error && (
                  <Message type="error">
                    <p className="hc-fs-md hc-ta-center">Error!</p>
                    <p className="hc-mt-xs hc-ta-center">
                      {error}. Please make sure you entered the correct store.
                    </p>
                  </Message>
                )}
              </Grid.Item>
              <Grid.Item xs={12}>
                <CreatableSelect
                  data-testid="vendor_name"
                  id="vendor_name"
                  required
                  label="Vendor Name"
                  name="Vendor Name"
                  options={options}
                  onChange={(e) => {
                    setVendorSubmitted(true);
                    setFormData({
                      ...formData,
                      vendor_name: e?.value,
                      address: storeAddress,
                    });
                  }}
                  createLabel="Add new Vendor Name..."
                  customPlaceholder="Select or enter Vendor Name"
                />
                {!vendorSubmitted && (
                  <p className="hc-fs-xs hc-clr-error hc-lh-dense">
                    <EnterpriseIcon icon={ErrorFilledIcon} size="inline" />{' '}
                    Please enter or select a vendor name!
                  </p>
                )}
              </Grid.Item>
              <Grid.Item xs={12}>
                <Form.Field
                  type="text"
                  data-testid="tech_name"
                  label="Technician Name"
                  id="technician_name"
                  required
                  error={errors.tech_name}
                  errorText={errors.tech_name && errors.tech_name.message}
                  {...register('tech_name', {
                    required: 'Please enter a Technician Name!',
                    pattern: {
                      value: /^[a-zA-Z_ ]*$/,
                      message:
                        'Invalid input! Please only use only [A-Z] characters and no special characters or numbers.',
                    },
                    onBlur: (e) => {
                      setFormData({
                        ...formData,
                        technician_name: e?.target?.value,
                      });
                    },
                  })}
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container align="center" justify="center">
              <Grid.Item>
                <Button
                  className="dividerMargin"
                  label="Next"
                  type="submit"
                  id="submit"
                />
              </Grid.Item>
            </Grid.Container>
          </Form>
        </Card>
      </Layout.Body>
    </>
  );
};
