import {
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Input,
  Spinner,
  Alert,
  Modal,
} from '@enterprise-ui/canvas-ui-react';
import {
  submitSurveyAction,
  submitSurveyActionExt,
} from '../../app/presurvey/surveyActionSlice';
import { useLocation, useNavigate, Link as ReactLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from 'ui-library';
import './styles/styles.scss';
import { VestibuleDetailsCard } from '../../components/VestibuleDetailsCard';
import { getFormSubmissionDetails } from '../../app/presurvey/formSlice';
import EnterpriseIcon, { CheckCircleFilledIcon } from '@enterprise-ui/icons';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

export const SurverySuccessPage = () => {
  window.scrollTo(0, 0);
  const auth = useAuth();
  const { session } = auth;
  const [token, setToken] = useState('');
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let [sessionSubmitted, setSessionSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { formData, vestibuleLabel, totalReaders } = location.state || {};
  const formSubmission = useSelector(getFormSubmissionDetails);
  const { loading, error } = useSelector((state) => state.vestibuleForm);
  let verifyLabel = sessionStorage
    ?.getItem('SurveysCompleted')
    ?.includes(vestibuleLabel);

  function updateLocalStorage() {
    if (!verifyLabel && Object.keys(formSubmission)?.length > 1 && !error) {
      //Added verification that form was actually sent
      //triggers conditional success function on the Vestibule Page
      let existingSurveys = [];
      if (JSON.parse(sessionStorage.getItem('SurveysCompleted'))?.length > 0) {
        existingSurveys = JSON.parse(
          sessionStorage.getItem('SurveysCompleted'),
        );
        existingSurveys?.push(vestibuleLabel);
        sessionStorage['SurveysCompleted'] = JSON.stringify(existingSurveys);
      } else {
        existingSurveys?.push(vestibuleLabel);
        sessionStorage['SurveysCompleted'] = JSON.stringify(existingSurveys);
      }
    }
  }

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  const handleSubmit = (e, approval) => {
    e.preventDefault();
    let payload = {
      location_id: formSubmission?.location_id,
      survey_results_status: approval,
    };
    if (session) {
      dispatch(submitSurveyAction(payload));
    } else if (user) {
      dispatch(submitSurveyActionExt({ payload, token }));
    }
  };

  updateLocalStorage();
  return (
    <>
      <Layout.Body data-testid="surveySuccessPage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Install Survey
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12} align="center" justify="center">
              {!error && Object.keys(formSubmission)?.length > 1 && (
                <Heading className="hc-ma-lg"> Success! </Heading>
              )}
              {error && <Heading className="hc-ma-lg"> Failed </Heading>}
            </Grid.Item>
            {Object.keys(formSubmission)?.length > 1 && !error && (
              <Grid.Item xs={12}>
                <Card>
                  <VestibuleDetailsCard data={formSubmission} />
                </Card>
              </Grid.Item>
            )}
            {loading && (
              <Grid.Item xs={12} align="center" justify="center">
                <Spinner />
              </Grid.Item>
            )}
          </Grid.Container>
          <Grid.Container align="center" justify="center">
            {(Object.keys(formSubmission)?.length < 1 || error) && (
              <Grid.Item xs={12}>
                <Card elevation={0} className="hc-pa-normal cardContainer">
                  <Input.Info error>
                    There was an issue submitting the form. Please try again.
                  </Input.Info>
                </Card>
              </Grid.Item>
            )}
            {!error &&
              Object.keys(formSubmission)?.length > 1 &&
              totalReaders ===
                JSON.parse(sessionStorage.getItem('SurveysCompleted'))
                  ?.length && (
                <Grid.Item xs={12}>
                  <Alert type="default">
                    <p className="hc-clr-contrast-weak">
                      Ready to submit? Be sure to verify all of your information
                      is correct.
                    </p>
                  </Alert>
                </Grid.Item>
              )}
            {formData?.location_id && (
              <Grid.Item className="hc-ma-normal">
                <Button
                  label="Return to Vestibules"
                  type="secondary"
                  name="next_button"
                  id="next_vestibule"
                  onClick={() => {
                    navigate(
                      `/survey-reader-list/?q=${formData?.location_id}`,
                      {
                        state: { formData },
                      },
                    );
                  }}
                />
              </Grid.Item>
            )}
            {!error &&
              Object.keys(formSubmission)?.length > 1 &&
              totalReaders ===
                JSON.parse(sessionStorage.getItem('SurveysCompleted'))
                  ?.length && (
                <Grid.Item className="hc-ma-normal">
                  <Button
                    label="Submit Session"
                    type="primary"
                    name="home_button"
                    id="home_exit"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />

                  <Modal
                    isVisible={isModalOpen}
                    headingText="Submit?"
                    onRefuse={() => setIsModalOpen(false)}
                  >
                    <div className="hc-pa-normal">
                      <Grid.Container>
                        <Grid.Item xs>
                          <p>Would you like to submit this survey session?</p>
                        </Grid.Item>
                      </Grid.Container>
                      <Grid.Container direction="row-reverse" spacing="dense">
                        <Button
                          className="hc-pa-normal hc-ma-normal"
                          onClick={(e) => {
                            let received = 'SURVEY_RECEIVED';
                            handleSubmit(e, received);
                            setIsModalOpen(false);
                            setSessionSubmitted(true);
                            setTimeout(() => {
                              navigate(`/`, sessionStorage.clear());
                            }, 10000);
                          }}
                          type="destructive"
                          label="Yes, Submit"
                        />

                        <Button
                          className="hc-pa-normal hc-ma-normal"
                          onClick={() => {
                            setIsModalOpen(false);
                          }}
                          type="secondary"
                          label="No, cancel"
                        />
                      </Grid.Container>
                    </div>
                  </Modal>
                </Grid.Item>
              )}
            {sessionSubmitted && (
              <Grid.Item
                xs={12}
                className="hc-mt-none hc-pt-none hc-pb-md hc-ta-center"
              >
                <p className="hc-clr-success hc-ma-none hc-pa-none">
                  <EnterpriseIcon
                    className="hc-clr-success"
                    icon={CheckCircleFilledIcon}
                  />
                  Your survey has been submitted! You will now be redirected to
                  the home page. Thank you!{' '}
                </p>
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
