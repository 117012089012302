import { useState, useEffect, useCallback } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useAuth } from '@praxis/component-auth';
import { TrackPageView } from '../../../utils/analyticsTracking';
import { useInstallerAuthContext } from '../../../hooks/useInstallerAuthContext';
import FileResizer from 'react-image-file-resizer';

import {
  addNewReader,
  addNewReaderExt,
  selectMacForDevices,
} from '../../../app/onboarding/onboardingSlice';
import {
  getReaderNames,
  getReaderNamesExt,
  selectDisplayNames,
  selectMacAddresses,
  selectAddress,
  selectLocationDetailsLoading,
} from '../../../app/onboarding/locationDetailsSlice';
import { codeCaptured } from '../../../app/scanner/scannerSlice';
import { createHostname, createFQDN } from '../../../utils/registrationHelpers';
import { convertObjArrayToSelectOptions } from '../../../utils/helpers';
import {
  Card,
  Grid,
  Breadcrumbs,
  Layout,
  Heading,
  Form,
  Input,
  Spinner,
} from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { ErrorFilledIcon } from '@enterprise-ui/icons';
import { Button } from 'ui-library';
import { Message } from '../../../components/Message';
import { SelectInput } from 'ui-library';
import { CreatableSelect } from '../../../components/CreatableSelect';
import { dataURIToBlob } from '../../../utils/helpers';

import styles from './OnboardingForm.module.scss';

export const OnboardingForm = () => {
  /* Auth related constants */
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  TrackPageView('/registration', 'onboardingForm');

  const macAddressScanned = useSelector((state) => state.scanner.value);
  const { loading, error } = useSelector((state) => state.onboarding);
  const registeredReaders = useSelector(selectMacForDevices);
  const displayNames = convertObjArrayToSelectOptions(
    useSelector(selectDisplayNames),
  );
  const macAddresses = convertObjArrayToSelectOptions(
    useSelector(selectMacAddresses),
  );
  const storeAddress = useSelector(selectAddress);
  const locationDetailsIsLoading = useSelector(selectLocationDetailsLoading);

  const {
    // Creates form handlers and errors
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      locationId: 'T',
    },
    mode: 'onBlur',
  });

  const [inputData, setInputData] = useState({
    logicalName: '',
    macAddress: '',
    hostName: '',
    modelNumber: 'fx7500',
    locationId: '',
    image: null,
    fqdn: '',
  });
  const [imgFile, setImgFile] = useState('');
  const [addReaderStatus, setAddReaderStatus] = useState('idle');
  const [location, setLocation] = useState('T');
  const [installLocation, setInstallLocation] = useState('T');
  const [token, setToken] = useState('');
  const [invalidMac, setInvalidMac] = useState(false);
  const [macAddressError, setMacAddressError] = useState('');
  let activeLocationId = location.length > 1 && storeAddress;

  // Populates select form fields when MAC address is scanned or installer user exists
  const prefillForm = useCallback(() => {
    if (macAddressScanned) {
      setInputData((prevData) => ({
        ...prevData,
        macAddress: macAddressScanned,
      }));

      createHostname(macAddressScanned).then((host) => {
        setInputData((prevData) => ({
          ...prevData,
          hostName: host,
        }));
      });
    }
  }, [macAddressScanned]);

  useEffect(() => {
    // Run prefill form onLoad when macAddress is scanned or if installer user exists
    if (user) {
      let { installLocation, token } = user;
      setInstallLocation(installLocation);
      setToken(token);
      setValue('locationId', installLocation);

      setInputData((prevData) => ({
        ...prevData,
        locationId: installLocation,
      }));
    }

    if (macAddressScanned) {
      setValue('macAddress', macAddressScanned);
      prefillForm();
    }
  }, [prefillForm, macAddressScanned, user, setValue]);

  useEffect(() => {
    if (location.length > 4) {
      dispatch(getReaderNames(location)); // If praxis session, dispatch action with automatic HTTP headers
    } else if (installLocation.length > 4) {
      // Otherwise, dispatch action with generated bearer token
      dispatch(getReaderNamesExt({ installLocation, token }));
    }
  }, [dispatch, location, installLocation, token]);

  useEffect(() => {
    // Creates hostname when macAddress is entered
    if (inputData.macAddress.length > 11) {
      createHostname(inputData.macAddress).then((host) => {
        setInputData((prevData) => ({
          ...prevData,
          hostName: host,
        }));
        // setHostname(host);
      });
    }
    // Sets domain after hostname is created
    if (inputData.hostName !== '') {
      let domain = createFQDN(inputData.hostName, inputData.locationId);
      setInputData((prevData) => ({
        ...prevData,
        fqdn: domain,
      }));
    }
  }, [inputData.macAddress, inputData.hostName, inputData.locationId]);

  useEffect(() => {
    // Allows image to be previewed
    let fileReader,
      isCancel = false;
    if (inputData.image) {
      fileReader = new FileReader();
      // readAsDataURL emits load events after completing the reading process
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setImgFile(result);
        }
      };
      fileReader.readAsDataURL(inputData.image);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [inputData.image]);

  useEffect(() => {
    // Stores successfully registered readers in local storage
    localStorage.setItem('rfidReaders', JSON.stringify(registeredReaders));
  }, [registeredReaders]);

  const handleFileUpload = (e) => {
    let file;

    if (e.dataTransfer?.files?.length) {
      file = e.dataTransfer.files[0];
    } else if (e.target?.value) {
      file = e.target.files[0];
    }

    if (file) {
      // 8MB max size * 1000KB * 1000B
      if (file.size > 8 * 1000 * 1000) {
        try {
          FileResizer.imageFileResizer(
            file,
            2000, // maxWidth
            2000, // maxHeight
            'JPEG', // compressFormat
            100, // quality
            0, // rotation
            (uri) => {
              let compressedFile = dataURIToBlob(uri);
              setInputData({
                ...inputData,
                image: compressedFile,
              });
            },
            'base64', // outputType
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        setInputData({
          ...inputData,
          image: file,
        });
      }
    }
  };

  const handleSelectChange = (e, actionType) => {
    if (
      actionType.action === 'select-option' ||
      actionType.action === 'create-option'
    ) {
      let isMacValid = true;
      if (actionType.action === 'create-option') {
        isMacValid = validateMac(e.value);
      } else if (actionType.action === 'select-option' && invalidMac) {
        setInvalidMac(false);
      }

      if (isMacValid) {
        let inputMac = e.value.toUpperCase();
        setInputData({ ...inputData, macAddress: inputMac });
      }
    }
  };

  // Validate mac address field
  const validateMac = (val) => {
    let currValue = val;
    let format = /^[a-f0-9]*$/i;
    if (currValue.length !== 12 || !format.test(currValue)) {
      setInvalidMac(true);
      setMacAddressError(
        'Invalid! The MAC address must be 12 characters long, containing only numbers and letters (A-F)',
      );
      return false;
    } else {
      setInvalidMac(false);
      return true;
    }
  };

  const canSubmit =
    [
      inputData.logicalName,
      inputData.macAddress,
      inputData.hostName,
      inputData.locationId,
      inputData.image,
    ].every(Boolean) &&
    addReaderStatus === 'idle' &&
    !invalidMac;

  // Gathers all metadata and sends it to API
  const submitForm = async () => {
    if (canSubmit) {
      try {
        setAddReaderStatus('pending');
        if (macAddressScanned) dispatch(codeCaptured(''));

        if (session) {
          await dispatch(addNewReader(inputData)).unwrap();
        } else if (user) {
          await dispatch(addNewReaderExt({ inputData, token })).unwrap();
        }

        for (let key in inputData) {
          if (typeof inputData[key] === 'string') {
            setInputData({ ...inputData, [key]: '' });
          }
        }

        navigate('/success', { replace: true });
      } catch (err) {
        console.log('Failed to register device!', err);
      } finally {
        setAddReaderStatus('idle');
      }
    }
  };

  return (
    <>
      <Layout.Body data-testid="onboardingForm" includeRail>
        <Card className={`hc-pa-normal ${styles.cardContainer}`}>
          <Grid.Container xs={12}>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/scan">
                  Scanner
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/registration">
                  Registration
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>
            <Grid.Item>
              <Heading size={3} className="hc-mb-md">
                Device Registration
              </Heading>
            </Grid.Item>
          </Grid.Container>
          {loading && <Spinner />}
          <Form
            role="form"
            id="registration_form"
            className="hc-mt-md"
            onSubmit={(e) => {
              e.preventDefault();
              submitForm(e);
            }}
          >
            <div className={styles.locationFieldWrapper}>
              <Form.Field
                id="locationId"
                data-testid="location_id"
                label="Location ID"
                type="text"
                required={true}
                className="hc-mb-md"
                error={errors.locationId}
                errorText={errors.locationId && errors.locationId.message}
                {...register('locationId', {
                  required: true,
                  pattern: {
                    value: /T[0-9]{4}/,
                    message:
                      'Invalid location ID! Please follow this format: T1234',
                  },
                  onBlur: (e) => {
                    setLocation(e.target.value);
                    setInputData({ ...inputData, locationId: e.target.value });
                  },
                })}
              />
              {locationDetailsIsLoading && (
                <Spinner className={styles.inlineTinySpinner} />
              )}
              {!errors.locationId && activeLocationId && (
                <p className={`hc-fs-xs ${styles.address}`}>
                  <strong>Address:</strong> <em>{storeAddress}</em>
                </p>
              )}
            </div>
            <SelectInput
              id="displayName"
              label="Display Name"
              name="displayName"
              data-testid="display_name"
              options={displayNames}
              required
              onChange={(e) => {
                let index = e.value.indexOf('-') - 1;
                let logicalName = e.value.substring(0, index);
                setInputData({ ...inputData, logicalName: logicalName });
              }}
              className="hc-mb-xl"
              aria-labelledby={`displayNameLabel`}
              classNamePrefix="customSelect"
            />
            <CreatableSelect
              id="macAddress"
              label="MAC Address**"
              name="macAddress"
              options={macAddresses}
              required
              onChange={handleSelectChange}
              // onBlur={validateMac}
              createLabel="Add new MAC address..."
              customPlaceholder="Select or enter new MAC address"
              className={invalidMac ? 'invalid-form-field' : ''}
            />
            {invalidMac ? (
              <p className="C-Input--type-info isErrored xs-neg-top-margin">
                <EnterpriseIcon
                  icon={ErrorFilledIcon}
                  size="inline"
                  className="hc-mr-min"
                />
                {macAddressError}
              </p>
            ) : (
              <p className="C-Input--type-info">
                <strong>
                  **Please type in your MAC address if not currently listed
                </strong>
              </p>
            )}
            {imgFile && (
              <div className={styles.imagePreviewWrapper}>
                {
                  <img
                    src={imgFile}
                    alt="preview"
                    className={styles.imagePreview}
                  />
                }
              </div>
            )}
            <Input.DropArea
              id="image"
              data-testid="image_upload"
              name="image"
              instructionText="Preferred image file formats are: .jpg, .jpeg, .png, or .svg"
              dropText="Add image*"
              onUpdate={handleFileUpload}
              className={`${styles.customDropArea} hc-mb-lg`}
              accept="image/*"
              required={true}
            />

            <Button
              label="Submit"
              type="submit"
              name="submit"
              id="submit"
              fullWidth
              disabled={!canSubmit}
            />
          </Form>
          {error && (
            <Message type="error">
              <p className="hc-fs-lg">Uh oh! There's a problem...</p>
              <p>The device failed to be onboarded. Please try again.</p>
            </Message>
          )}
        </Card>
      </Layout.Body>
    </>
  );
};
