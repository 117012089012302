import { Link as ReactLink } from 'react-router-dom';
import { useAuth } from '@praxis/component-auth';
import { AdminSection } from '../../components/AdminSection';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  Card,
  Grid,
  Heading,
  Layout,
  List,
  Anchor,
} from '@enterprise-ui/canvas-ui-react';
import {
  EnterpriseIcon,
  DeviceIcon,
  ChevronRightIcon,
} from '@enterprise-ui/icons';

import styles from './Homepage.module.scss';

export const Homepage = () => {
  const auth = useAuth();
  const { session } = auth;

  TrackPageView('/', 'homepage');

  return (
    <>
      <Layout.Body data-testid="home" includeRail>
        <Card className={`hc-pa-normal cardContainer ${styles.flexCard}`}>
          <Grid.Container className={styles.mainContainer}>
            <Grid.Item>
              <Grid.Container className={styles.headingContainer}>
                <Grid.Item>
                  <EnterpriseIcon
                    icon={DeviceIcon}
                    size="lg"
                    className={styles.fullHeightDeviceIcon}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Heading
                    size={3}
                    className={`hc-ta-center ${styles.shortHeading}`}
                  >
                    Welcome to the RFID Installer!
                  </Heading>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item className="hc-ta-center">
              <p className="hc-fs-md">What would you like to do?</p>
            </Grid.Item>
            <Grid.Item>
              <List>
                <List.Item className="hc-ta-center">
                  <Anchor to="/install" className="iot-link" as={ReactLink}>
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Install new reader
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor to="/lookup" className="iot-link" as={ReactLink}>
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Check existing reader
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor
                    to="/calibrationSetupLocation"
                    className="iot-link"
                    as={ReactLink}
                  >
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Calibrate readers
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor to="/survey" className="iot-link" as={ReactLink}>
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Pre-Install Survey
                  </Anchor>
                </List.Item>
                {session && (
                  <List.Item className="hc-ta-center">
                    <Anchor
                      to="/surveySearch"
                      className="iot-link"
                      as={ReactLink}
                    >
                      <EnterpriseIcon
                        icon={ChevronRightIcon}
                        size="inline"
                        className="inline-icon"
                      />
                      Survey Search
                    </Anchor>
                  </List.Item>
                )}
              </List>
            </Grid.Item>
          </Grid.Container>
          {session && <AdminSection />}
        </Card>
      </Layout.Body>
    </>
  );
};
