import {
  Card,
  Grid,
  Layout,
  InputRadio,
  Breadcrumbs,
  Heading,
  Input,
  Anchor,
  Alert,
  Spinner,
  Modal,
} from '@enterprise-ui/canvas-ui-react';
import {
  vestibulesByStore,
  vestibulesByStoreExt,
  getVestibulesDetails,
  getAddressDetails,
} from '../../app/presurvey/surveySlice';
import {
  submitSurveyAction,
  submitSurveyActionExt,
} from '../../app/presurvey/surveyActionSlice';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { Link as ReactLink } from 'react-router-dom';
import { Button } from 'ui-library';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles/styles.scss';
import { convertTwoArrayiIntoOne } from '../../utils/helpers';
import EnterpriseIcon, { CheckCircleFilledIcon } from '@enterprise-ui/icons';

export const VestibuleList = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reader = useSelector(getVestibulesDetails);
  const address = useSelector(getAddressDetails);
  const [searchParams] = useSearchParams();
  const store = searchParams?.get('q');
  let [vestibuleValue, setVestibuleValue] = useState();
  let [vestibuleLabel, setVestibuleLabel] = useState();
  let [sessionSubmitted, setSessionSubmitted] = useState(false);
  const [isHomeModalOpen, setisHomeModalOpen] = useState(false);
  const [isSubmitModalOpen, setisSubmitModalOpen] = useState(false);
  const readerState = convertTwoArrayiIntoOne(reader);
  const [formData, setFormData] = useState(location?.state?.formData);

  const { loading, error } = useSelector((state) => state?.vestibuleLocation);

  useEffect(() => {
    if (store !== null) {
      if (session) {
        dispatch(vestibulesByStore(store));
      } else if (user) {
        let { token } = user;
        dispatch(vestibulesByStoreExt({ store, token }));
      }
    }
  }, [dispatch, session, store, user]);

  // Load completed surveys from sessionStorage and add classes after render
  useEffect(() => {
    const surveysCompleted = new Set(
      JSON?.parse(sessionStorage?.getItem('SurveysCompleted')) || [],
    );

    if (readerState?.length > 0) {
      // Use setTimeout to ensure DOM updates after rendering
      setTimeout(() => {
        document
          ?.querySelectorAll('fieldset.C-InputRadio label')
          ?.forEach((label) => {
            const labelText = label.textContent;
            if (surveysCompleted?.has(labelText)) {
              label?.classList?.add('vestibule-list-elem');
            }
          });
      }, 0);
    }
  }, [readerState]);

  const handleSubmit = (e, received) => {
    e.preventDefault();
    let payload = {
      location_id: store,
      survey_results_status: received,
    };
    if (session) {
      dispatch(submitSurveyAction(payload));
    } else if (user) {
      let { token } = user;
      dispatch(submitSurveyActionExt({ payload, token }));
    }
  };

  function savePrevInput() {
    if (sessionStorage?.getItem(vestibuleValue)?.length < 1) {
      sessionStorage.setItem(vestibuleValue, '');
    }
  }

  function clearAllExceptTheseFields(arr, fields) {
    //clears out uneccessary obj keys for new data input
    Object?.keys(arr)?.forEach((b) => {
      if (!fields?.includes(b)) {
        delete formData[b];
      }
    });
  }

  clearAllExceptTheseFields(formData || {}, [
    'location_id',
    'address',
    'technician_name',
    'vendor_name',
    'vestibule_name',
    'neptune_x',
    'neptune_y',
  ]);

  return (
    <>
      <Layout.Body data-testid="VestibuleList" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Install Survey
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}> Vestibules List </Heading>
            </Grid.Item>
            {JSON.parse(sessionStorage?.getItem('SurveysCompleted'))?.length >
              0 && (
              <Grid.Item xs={12} className="hc-pb-none">
                <Alert type="default">
                  <p className="hc-clr-contrast-weak">
                    {' '}
                    Forgot something? Choose a vestibule to edit, then resubmit
                    your form.
                  </p>
                </Alert>
              </Grid.Item>
            )}
            {reader && !error && !loading && location?.state?.formData && (
              <Grid.Item xs={12}>
                <p>
                  {' '}
                  To begin the survey at <strong>{store} </strong>, please
                  select a Vestibule from the list:
                </p>
                <InputRadio
                  id="vestibule_store"
                  options={readerState}
                  required
                  onChange={(e) => {
                    setVestibuleValue(e?.target?.value?.split('_')?.[0]);
                    setVestibuleLabel(e?.target?.labels[0]?.textContent);
                    setFormData({
                      ...formData,
                      vestibule_name: e?.target?.value
                        ?.split('_')?.[0]
                        ?.slice(1),
                      address: address,
                      neptune_x:
                        Number(e?.target?.value?.split('_')?.[1]) * 1.0,
                      neptune_y:
                        Number(e?.target?.value?.split('_')?.[2]) * 1.0,
                    });
                  }}
                />
              </Grid.Item>
            )}
            {(error && !loading) ||
              ((!location?.state?.formData || readerState === 'undefined') && (
                <Card elevation={0} className="hc-pa-normal cardContainer">
                  <Input.Info error>
                    There was an error while fetching this location. Please
                    start a{' '}
                    <Anchor to="/survey" as={ReactLink} className="iot-link">
                      new session.{' '}
                    </Anchor>{' '}
                  </Input.Info>
                </Card>
              ))}
            {loading && !error && (
              <Grid.Item xs={12}>
                <Spinner className="spinnerSize" />
              </Grid.Item>
            )}
          </Grid.Container>
          <Grid.Container align="center" justify="center">
            <Grid.Item>
              <Button
                label="Start Over"
                type="primary"
                name="prev_button"
                id="prev_button"
                className="dividerMargin"
                onClick={() => {
                  setisHomeModalOpen(true);
                }}
              />
              <Modal
                isVisible={isHomeModalOpen}
                headingText="Your data is about to be deleted!"
                onRefuse={() => setisHomeModalOpen(false)}
              >
                <div className="hc-pa-normal">
                  <Grid.Container>
                    <Grid.Item xs>
                      <p className="hc-pa-normal">
                        Would you like to start over your current session?
                      </p>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Button
                      className="hc-pa-normal hc-ma-normal"
                      onClick={() => {
                        setisHomeModalOpen(false);
                        navigate(
                          `/survey`,
                          {
                            state: { formData },
                          },
                          sessionStorage.clear(),
                        );
                      }}
                      type="destructive"
                      label="Yes, start over"
                    />

                    <Button
                      className="hc-pa-normal hc-ma-normal"
                      onClick={() => {
                        setisHomeModalOpen(false);
                      }}
                      type="secondary"
                      label="No, cancel"
                    />
                  </Grid.Container>
                </div>
              </Modal>
            </Grid.Item>
            {vestibuleValue && (
              <Grid.Item>
                <Button
                  label="Next"
                  type="submit"
                  name="next_button"
                  id="submit"
                  className="dividerMargin"
                  onClick={() => {
                    savePrevInput();
                    if (vestibuleLabel?.includes('TM Entrance')) {
                      navigate(
                        `/survey-teamMember/?TM=${vestibuleValue?.slice(-1)}&floor=${vestibuleValue?.slice(0, 1)}`,
                        {
                          state: {
                            formData,
                            vestibuleValue,
                            vestibuleLabel,
                            coordinates: {
                              x: formData?.neptune_x,
                              y: formData?.neptune_y,
                            },
                            totalReaders: readerState?.length,
                          },
                        },
                      );
                    } else {
                      navigate(
                        `/survey-vestibule/?vestibule=${vestibuleValue?.slice(-1)}&floor=${vestibuleValue?.slice(0, 1)}`,
                        {
                          state: {
                            formData,
                            vestibuleValue,
                            vestibuleLabel,
                            coordinates: {
                              x: formData?.neptune_x,
                              y: formData?.neptune_y,
                            },
                            totalReaders: readerState?.length,
                          },
                        },
                      );
                    }
                  }}
                />
              </Grid.Item>
            )}
            {readerState?.length ===
              JSON.parse(sessionStorage?.getItem('SurveysCompleted'))
                ?.length && (
              <Grid.Item>
                <Button
                  label="Submit Session"
                  type="secondary"
                  name="home_button"
                  id="home_exit"
                  className="dividerMargin"
                  onClick={() => {
                    setisSubmitModalOpen(true);
                  }}
                />
                <Modal
                  isVisible={isSubmitModalOpen}
                  headingText="Submit?"
                  onRefuse={() => setisSubmitModalOpen(false)}
                >
                  <div className="hc-pa-normal">
                    <Grid.Container>
                      <Grid.Item xs>
                        <p>Would you like to submit this survey session?</p>
                      </Grid.Item>
                    </Grid.Container>
                    <Grid.Container direction="row-reverse" spacing="dense">
                      <Button
                        className="hc-pa-normal hc-ma-normal"
                        onClick={(e) => {
                          let received = 'SURVEY_RECEIVED';
                          handleSubmit(e, received);
                          setisSubmitModalOpen(false);
                          setSessionSubmitted(true);
                          setTimeout(() => {
                            navigate(`/`, sessionStorage.clear());
                          }, 10000);
                        }}
                        type="destructive"
                        label="Yes, Submit"
                      />

                      <Button
                        className="hc-pa-normal hc-ma-normal"
                        onClick={() => {
                          setisSubmitModalOpen(false);
                        }}
                        type="secondary"
                        label="No, cancel"
                      />
                    </Grid.Container>
                  </div>
                </Modal>
              </Grid.Item>
            )}
            {sessionSubmitted && (
              <Grid.Item
                xs={12}
                className="hc-mt-none hc-pt-none hc-pb-md hc-ta-center"
              >
                <p className="hc-clr-success hc-ma-none hc-pa-none">
                  {' '}
                  <EnterpriseIcon
                    className="hc-clr-success"
                    icon={CheckCircleFilledIcon}
                  />
                  Your survey has been submitted! Now, you will be redirected to
                  the home page. Thank you!{' '}
                </p>
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
