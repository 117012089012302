export const TMBlockers = () => {
  const options = [
    {
      value: 'Inaccessible Hard Lid Ceiling', //Placeholder!
      label: 'Inaccessible Hard Lid Ceiling',
    },
    {
      value: 'HVAC Unit Obstruction',
      label: 'HVAC Unit Obstruction',
    },
    {
      value: 'Lighting Fixture Obstruction',
      label: 'Lighting Fixture Obstruction',
    },
    {
      value: 'Sprinkler Head Interference',
      label: 'Sprinkler Head Interference',
    },
    {
      value: 'I-Beam or Red Iron Beam Obstruction',
      label: 'I-Beam or Red Iron Beam Obstruction',
    },
    {
      value: 'Glass Front Barrier',
      label: 'Glass Front Barrier',
    },
    {
      value: 'Ceiling Height Restriction',
      label: 'Ceiling Height Restriction',
    },
    {
      value: 'Low Soffit Depth',
      label: 'Low Soffit Depth',
    },
    {
      value: 'Vent Blockage',
      label: 'Vent Blockage',
    },
    {
      value: 'No Crawl Space Access',
      label: 'No Crawl Space Access',
    },
    {
      value: 'Exit Sign Blockage',
      label: 'Exit Sign Blockage',
    },
    {
      value: 'Wall Thickness',
      label: 'Wall Thickness',
    },
    {
      value: 'Fire Sprinkler',
      label: 'Fire Sprinkler',
    },
    {
      value: 'Space around door',
      label: 'Space around door',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  return options;
};

export const anyObstruction = () => {
  const options = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];
  return options;
};

export const InteriorOrExterior = () => {
  const options = [
    {
      value: 'Interior',
      label: 'Interior',
    },
    {
      value: 'Exterior',
      label: 'Exterior',
    },
  ];
  return options;
};

export const SinlgeOrDoubleDoors = () => {
  const options = [
    {
      value: 'Single',
      label: 'Single',
    },
    {
      value: 'Double',
      label: 'Double',
    },
  ];
  return options;
};

export const LeftSideOrRightSide = () => {
  const options = [
    {
      value: 'Left Door',
      label: 'Left Door',
    },
    {
      value: 'Right Door',
      label: 'Right Door',
    },
  ];
  return options;
};

export const InStoreOrOutward = () => {
  const options = [
    {
      value: 'Towards store.',
      label: 'Towards store.',
    },
    {
      value: 'Outwards towards outside.',
      label: 'Outwards towards outside.',
    },
  ];
  return options;
};

export const VestibuleBlockers = () => {
  const options = [
    {
      value: 'Inaccessible Hard Lid Ceiling', //Placeholder!
      label: 'Inaccessible Hard Lid Ceiling',
    },
    {
      value: 'HVAC Unit Obstruction',
      label: 'HVAC Unit Obstruction',
    },
    {
      value: 'Lighting Fixture Obstruction',
      label: 'Lighting Fixture Obstruction',
    },
    {
      value: 'Sprinkler Head Interference',
      label: 'Sprinkler Head Interference',
    },
    {
      value: 'I-Beam or Red Iron Beam Obstruction',
      label: 'I-Beam or Red Iron Beam Obstruction',
    },
    {
      value: 'Glass Front Barrier',
      label: 'Glass Front Barrier',
    },
    {
      value: 'Ceiling Height Restriction',
      label: 'Ceiling Height Restriction',
    },
    {
      value: 'Low Soffit Depth',
      label: 'Low Soffit Depth',
    },
    {
      value: 'Vent Blockage',
      label: 'Vent Blockage',
    },
    {
      value: 'No Crawl Space Access',
      label: 'No Crawl Space Access',
    },
    {
      value: 'Exit Sign Blockage',
      label: 'Exit Sign Blockage',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  return options;
};

export const VestibuleType = () => {
  const options = [
    {
      value: 'Type A',
      label: 'Type A: Vestibule with one door and a hybrid divider wall',
    },
    {
      value: 'Type B',
      label: 'Type B: Vestibule with cart lanes and one door',
    },
    {
      value: 'Type C',
      label: 'Type C: Vestibule with two door lanes and a hybrid divider wall',
    },
    {
      value: 'Type D',
      label: 'Type D: Open vestibule with multiple double doors',
    },
    {
      value: 'Type E',
      label:
        'Type E: Mall or Target entrances near escalators, with no vestibule',
    },
    {
      value: 'Type F',
      label: 'Type F: No vestibule or other unique entrance types',
    },
  ];
  return options;
};
