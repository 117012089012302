import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent, LogLevel } from '@praxis/component-logging';
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link as ReactLink,
} from 'react-router-dom';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

import { getMap, getMapExt } from '../../app/neptune/neptuneSlice';
import { submitNewForm, submitNewFormExt } from '../../app/presurvey/formSlice';
import { dataURIToBlob } from '../../utils/helpers';
import FileResizer from 'react-image-file-resizer';
import {
  Card,
  Grid,
  Layout,
  Breadcrumbs,
  Heading,
  Form,
  Input,
  Spinner,
  Anchor,
  Alert,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react';
import { SurveyMap } from '../../components/SurveyMap';
import { Button, SelectInput } from 'ui-library';
import {
  InteriorOrExterior,
  LeftSideOrRightSide,
  SinlgeOrDoubleDoors,
  TMBlockers,
  anyObstruction,
} from './options';

import './styles/styles.scss';

export const TeamMemberTemplate = () => {
  /* Auth related constants */
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  /* Navigation related constants */
  const location = useLocation()?.state;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const vestibule_number = searchParams.get('vestibule');
  let { vestibuleValue, vestibuleLabel, coordinates, totalReaders } =
    location ?? {};
  const floor_number = searchParams.get('floor');
  let prevData = JSON?.parse(sessionStorage?.getItem(vestibuleValue));
  let [formData, setFormData] = useState({
    ...location?.formData,
    ...prevData,
  });
  const [didPhotosUpload, setDidPhotosUpload] = useState(false);
  let [submissionDispatch, setSubmissionDispatch] = useState(false);
  let [maxPhotoLimitHit, setMaxPhotoLimitHit] = useState(false);
  /* Location State related data*/

  const store = location?.formData?.location_id;
  const [convertFiles, setConvertFiles] = useState([]);

  const response = useSelector((state) => state.neptune);
  const { loading, error } = useSelector((state) => state.vestibuleForm);

  function conditionalForDoorType(doorType, bothDoors) {
    if (doorType === 'Double') {
      if (bothDoors === 'No') {
        return [formData?.door_placement].every(Boolean);
      } else return [bothDoors].every(Boolean);
    } else return true;
  }

  function conditionalForObstructionReason(hasObstruction, obstructionReason) {
    if (hasObstruction === 'Yes') {
      if (obstructionReason === 'Other') {
        return [formData?.obstruction_other_reason].every(Boolean);
      } else return [obstructionReason].every(Boolean);
    } else return true;
  }

  const canSubmit =
    [
      formData?.floor_id,
      formData?.door_type,
      formData?.ceiling_height,
      formData?.is_obstructed_label,
      formData?.door_position,
    ].every(Boolean) &&
    conditionalForDoorType(
      formData?.door_type,
      formData?.is_each_door_used_label,
    ) &&
    conditionalForObstructionReason(
      formData?.is_obstructed_label,
      formData?.obstruction_reason,
    );

  const handleSubmit = () => {
    dispatchEndpoint();
  };

  function doorImagepicker(type) {
    switch (type) {
      case 'Interior':
        return '/images/Interior.png';
      case 'Exterior':
        return '/images/Exterior.png';
      default:
        return 'err';
    }
  }

  const dispatchEndpoint = async () => {
    if (convertFiles?.length >= 2) {
      try {
        if (session) {
          await dispatch(submitNewForm(formData))?.unwrap();
          setSubmissionDispatch(false);
        } else if (user) {
          let { token } = user;
          await dispatch(submitNewFormExt({ formData, token }))?.unwrap();
          setSubmissionDispatch(false);
        }
      } catch (err) {
        setSubmissionDispatch(true);
        logEvent(
          {
            message: err,
          },
          { level: LogLevel.Error },
        );
      } finally {
        if (!error && !loading && !submissionDispatch) {
          navigate(`/survey-success`, {
            state: {
              formData,
              vestibuleValue,
              vestibuleLabel,
              totalReaders,
            },
          });
        } else {
          setSubmissionDispatch(false);
        }
      }
    } else {
      setDidPhotosUpload(true);
    }
  };

  const handlePhotoForm = () => {
    for (let i = convertFiles?.length + 1; i <= 9; i++) {
      delete formData['file' + i];
    }
    if (convertFiles) {
      for (let i = 1; i <= convertFiles?.length; i++) {
        let newFile = convertFiles[i - 1];
        formData['file' + i] = newFile;
      }
    }
  };

  function pushPhotoIntoArray(event) {
    let fileList = event?.target?.files;
    let currentFilesLength = convertFiles?.length;
    Object.values(fileList)?.forEach(function (key) {
      if (key.size > 1 * 1000 * 1000) {
        FileResizer.imageFileResizer(
          key,
          1000, // maxWidth
          1000, // maxHeight
          'JPEG', // compressFormat
          100, // quality
          0, // rotation
          (uri) => {
            let compressedFile = dataURIToBlob(uri);
            if (currentFilesLength + fileList?.length < 9) {
              setConvertFiles((convertFiles) => [
                ...convertFiles,
                compressedFile,
              ]);
            } else {
              setMaxPhotoLimitHit(true);
            }
          },
          'base64', // outputType
        );
      } else {
        if (currentFilesLength + fileList?.length < 9) {
          setConvertFiles((convertFiles) => [...convertFiles, key]);
        } else {
          setMaxPhotoLimitHit(true);
        }
      }
    });
  }

  useEffect(() => {
    if (formData?.floor_id === undefined || null) {
      setFormData({
        ...formData,
        vestibule_type: 'Type TM',
        floor_id: floor_number,
        entrance_door_count: 0,
        exit_door_count: 0,
        entrance_door_width: 0,
        exit_door_width: 0,
      });
    }
  }, [formData, floor_number]);

  const removeItem = (index) => {
    setConvertFiles(convertFiles.filter((o, i) => index !== i));
    // delete formData['file' + (index + 1)];
  };

  useEffect(
    function dispatchMapRequest() {
      if (store) {
        let storeId = store.substr(1);
        let floorNum = floor_number;
        if (user) {
          let { token } = user;
          dispatch(getMapExt({ storeId, floorNum, token }));
        } else {
          dispatch(getMap({ storeId, floorNum }));
        }
      }
    },
    [dispatch, store, floor_number, user],
  );

  return (
    <>
      <Layout.Body data-testid="vestibuleFormPage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Install Survey
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>
                {' '}
                <Heading size={3}> TM Entrance - Floor {floor_number} </Heading>
              </Heading>
            </Grid.Item>
            {response?.error && (
              <Grid.Item xs={12}>
                <Card elevation={0}>
                  <Input.Info error>Map is currently unavailable.</Input.Info>
                </Card>
              </Grid.Item>
            )}
            {!response?.error && !response?.loading && coordinates && (
              <Grid.Item xs={12}>
                <SurveyMap
                  storeId={store}
                  floorNum={floor_number}
                  coordinates={coordinates}
                />
              </Grid.Item>
            )}
            {response?.loading && !response?.error && <Spinner />}
            {location?.formData && store ? (
              <Form
                role="form"
                id="registration_form"
                className="hc-mt-md"
                onSubmit={(e) => {
                  e.preventDefault();
                  sessionStorage.setItem(
                    vestibuleValue,
                    JSON?.stringify({ ...prevData, ...formData }),
                  );
                  handlePhotoForm();
                  handleSubmit();
                }}
              >
                <Grid.Container className="hc-pa-normal" align="center">
                  <Grid.Item xs={12}>
                    <p className="hc-fs-md">Vestibule Type: Type TM</p>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <SelectInput
                      id="door_position"
                      label="Is this an interior or exterior door?"
                      name="door_position"
                      classNamePrefix="customSelect"
                      options={InteriorOrExterior()}
                      required
                      defaultValue={{
                        value: formData?.door_position,
                        label: formData?.door_position,
                      }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          door_position: e?.value,
                        });
                      }}
                    />
                    {formData?.door_position && (
                      <ExpandableSection className="hc-bg-grey06">
                        <p className="hc-pt-none">
                          Tap here to view example image
                        </p>
                        <ExpandableSection.Content className="hc-pa-none">
                          <img
                            width="100%"
                            height="100%"
                            src={doorImagepicker(formData?.door_position)}
                            alt="first_image"
                          />
                        </ExpandableSection.Content>
                      </ExpandableSection>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <SelectInput
                      data-testid="single_or_double_doors"
                      id="single_or_double_doors"
                      label="Is this a single-door or double-door setup?"
                      classNamePrefix="customSelect"
                      defaultValue={{
                        value: formData?.door_type,
                        label: formData?.door_type,
                      }}
                      required
                      options={SinlgeOrDoubleDoors()} //simplified options
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          door_type: e?.value,
                        });
                      }}
                    />
                  </Grid.Item>
                  {(formData?.door_type || prevData?.door_type) ===
                    'Double' && (
                    <Grid.Item xs={12}>
                      <SelectInput
                        data-testid="TM_use_both_doors"
                        id="TM_use_both_doors"
                        classNamePrefix="customSelect"
                        label="Do TMs use both doors for entry and/or exit?"
                        defaultValue={{
                          value: formData?.is_each_door_used,
                          label: formData?.is_each_door_used_label,
                        }}
                        required
                        options={anyObstruction()} //simplified options
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            is_each_door_used: Boolean(e?.value),
                            is_each_door_used_label: e?.label,
                          });
                        }}
                      />
                    </Grid.Item>
                  )}
                  {(formData?.door_type || prevData?.door_type) === 'Double' &&
                    (formData?.is_each_door_used_label ||
                      prevData?.is_each_door_used_label) === 'No' && (
                      <Grid.Item xs={12}>
                        <SelectInput
                          data-testid="left_or_right_side"
                          id="left_or_right_side"
                          label="From inside the store, facing the exterior, which door do TMs use?"
                          defaultValue={{
                            value: formData?.door_placement,
                            label: formData?.door_placement,
                          }}
                          classNamePrefix="customSelect"
                          required
                          options={LeftSideOrRightSide()} //simplified options
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              door_placement: e?.value,
                            });
                          }}
                        />
                      </Grid.Item>
                    )}
                  <Grid.Item xs={12}>
                    <Heading size={6}>Height of Ceiling:</Heading>
                  </Grid.Item>
                  <Grid.Item xs={5}>
                    <Form.Field
                      data-testid="ceiling_height"
                      type="number"
                      value={formData?.ceiling_height_feet}
                      id="ceiling_height"
                      min={0}
                      max={500}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ceiling_height_feet: e?.target?.value,
                          ceiling_height:
                            e?.target?.value +
                            "' " +
                            formData?.ceiling_height_inches,
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <p className="text"> ft</p>
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      data-testid="ceiling_height"
                      type="number"
                      value={formData?.ceiling_height_inches}
                      id="ceiling_height"
                      min={0}
                      max={12}
                      step="0.1"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ceiling_height_inches: e?.target?.value,
                          ceiling_height:
                            formData?.ceiling_height_feet +
                            "' " +
                            e?.target?.value,
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <p className="text">in</p>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <SelectInput
                      data-testid="is_obstructed"
                      id="is_obstructed"
                      label="Are there any obstructions to the install"
                      classNamePrefix="customSelect"
                      defaultValue={{
                        value: formData?.is_obstructed,
                        label: formData?.is_obstructed_label,
                      }}
                      required
                      errorText="You must select an option"
                      options={anyObstruction()}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          is_obstructed: Boolean(e?.value),
                          is_obstructed_label: e?.label,
                        });
                      }}
                    />
                  </Grid.Item>
                  {(formData?.is_obstructed_label ||
                    prevData?.is_obstructed_label) === 'Yes' && (
                    <Grid.Item xs={12}>
                      <SelectInput
                        data-testid="obstruction_reason"
                        id="obstruction_reason"
                        label="Please select reason for obstruction:"
                        classNamePrefix="customSelect"
                        defaultValue={{
                          value: formData?.obstruction_reason,
                          label: formData?.obstruction_reason,
                        }}
                        required
                        options={TMBlockers()} //simplified options
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            obstruction_reason: e?.value,
                          });
                        }}
                      />
                    </Grid.Item>
                  )}
                  {(formData?.obstruction_reason ||
                    prevData?.obstruction_reason) === 'Other' &&
                    (formData?.is_obstructed_label ||
                      prevData?.is_obstructed_label) === 'Yes' && (
                      <Grid.Item xs={12}>
                        <Form.Field
                          data-testid="obstruction_other_reason"
                          id="obstruction_other_reason"
                          label="Please type reason for being unable to install:"
                          value={formData?.obstruction_other_reason}
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              obstruction_other_reason: e?.target?.value,
                            });
                          }}
                        />
                      </Grid.Item>
                    )}
                  <Grid.Item xs={12}>
                    <Form.Field
                      data-testid="other_details"
                      value={formData?.additional_notes}
                      id="more_details"
                      type="textarea"
                      label="Other details if applicable"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          additional_notes: e?.target?.value || '',
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    {convertFiles.map((photo, index) => {
                      return (
                        <div className="hc-clr-contrast-weak">
                          <Alert
                            key={index}
                            type="success"
                            heading="Success!"
                            className="hc-clr-contrast-weak hc-bg-success hc-mb-normal hc-mt-none"
                            onClose={() => {
                              removeItem(index);
                            }}
                          >
                            {photo.name} has been submitted!{' '}
                          </Alert>
                        </div>
                      );
                    })}
                    {didPhotosUpload && (
                      <Alert
                        type="error"
                        heading="Error: Missing photos"
                        className="hc-mb-normal hc-mt-none"
                      >
                        Please upload <strong>at least two</strong> photos of
                        the vestibule.{' '}
                      </Alert>
                    )}
                    {maxPhotoLimitHit && (
                      <Alert
                        type="error"
                        heading="Error: Maximum limit exceeded"
                        className="hc-mb-normal hc-mt-none"
                      >
                        <p>
                          Your photo selection exceeds the maximum limit of 8.
                          Please try again.
                        </p>
                      </Alert>
                    )}
                    <Input.Label>
                      Please upload at least <strong>two</strong> photos of
                      vestibule.{' '}
                      <strong>Maximum number of photos allowed is 8:</strong>
                    </Input.Label>
                    <Input.DropArea
                      data-testid="photo_area"
                      id="photo_area"
                      size="expanded"
                      accept=".jpeg,.jpg,.png,.heic,.avif,.webp"
                      multiple
                      dropText="Upload your photos here"
                      onUpdate={(event) => {
                        setMaxPhotoLimitHit(false);
                        pushPhotoIntoArray(event);
                        setDidPhotosUpload(false);
                      }}
                      clickOnly
                      fullwidth
                      instructionText="Maximum number of photos allowed is 8."
                    />
                  </Grid.Item>
                </Grid.Container>

                {loading && (
                  <Grid.Item xs={12} align="center" justify="center">
                    <Spinner />
                  </Grid.Item>
                )}
                {submissionDispatch && (
                  <Grid.Item xs={12} align="center" justify="center">
                    <p className="hc-clr-error">
                      There was a error while submitting your form. Please try
                      resubmitting or restarting your session.
                    </p>
                  </Grid.Item>
                )}
                <Grid.Item xs={12} align="center" justify="center">
                  <Button
                    className="hc-ma-normal"
                    label="Prev"
                    type="secondary"
                    name="prev_button"
                    id="prev_button"
                    onClick={() => {
                      sessionStorage.setItem(
                        vestibuleValue,
                        JSON?.stringify(formData),
                      );
                      navigate(`/survey-reader-list/?q=${store}`, {
                        state: { formData },
                      });
                    }}
                  />

                  <Button
                    className="hc-ma-normal"
                    label="Continue"
                    type="submit"
                    name="next_button"
                    id="submit"
                    disabled={!canSubmit}
                  />
                </Grid.Item>
              </Form>
            ) : (
              <Card elevation={0} className="hc-pa-normal cardContainer">
                <Input.Info error>
                  There was an issue loading the details for this location.
                  Please make sure{' '}
                  <Anchor to="/survey" as={ReactLink} className="iot-link">
                    previous information{' '}
                  </Anchor>{' '}
                  was submitted correctly.
                </Input.Info>
              </Card>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
